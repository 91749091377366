/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  // dashboard api servers calls
  GET_USER_INFO: '/dashboard-api/user/info',
  GET_PROJECTS: '/dashboard-api/projects',
  GET_REQUEST_ACCESS_PROJECTS: 'dashboard-api/projects/allowaccess',
  GET_ENTITY_OR_PRODUCTID_BY_SEARCH_QUERY:
    'dashboard-api/projects/getEntityUrnsOrProductIdsBySearchQuery',
  GET_PROJECT_BY_DURN_ENTITYURN:
    '/dashboard-api/projects/version/%dURN%/entity/%eURN%',
  GET_PROJECT_BY_DURN: '/dashboard-api/projects/version/%dURN%',
  CREATE_NEW_VERSION: '/dashboard-api/projects/%dURN%/newversion',
  UPDATE_GLOSSARY: '/dashboard-api/projects/%dURN%/updateGlossary',
  PUBLISH_URL: 'dashboard-api/publish/%dURN%/%destination%/%learningtool%',
  GET_ALFRESCO_SITE_ID: 'dashboard-api/alfresco/nodeRef/%nodeRef%/siteId',
  VALIDATE_PROJECT_BEFORE_PUBLISH: '/dashboard-api/publish/%dURN%/validate',
  TOGGLE_FAVOURITE_URL: '/dashboard-api/projects/toggleFavourite',
  GET_FAVOURITE_PROJECTS: '/dashboard-api/projects/byFavourites',
  GET_FAVOURITE_ENTITY_URNS: '/dashboard-api/user/favourites',
  VALIDATE_PRODUCT_ID: '/dashboard-api/product/%entityUrn%/%productId%',
  UPDATE_PRODUCT_ID: '/dashboard-api/projects/%dURN%/productID/%productId%',
  REMOVE_PRODUCT_ID: '/dashboard-api/projects/%dURN%/productId/remove',
  UPDATE_QUAD_FOLDER: '/dashboard-api/projects/%dURN%/updateQuadFolderNote',
  GET_VERSIONS: '/dashboard-api/projects/entity/%entityUrn%/versions',
  GET_PUBLISH_STATUS: '/dashboard-api/product/%dUrn%/checkLiveStatus',
  GET_LAST_OPENED_BY_ME_PROJECTS: '/dashboard-api/projects/byLastOpened',
  GET_OWNERS_TRACK_CHANGES_STATUS:
    '/dashboard-api/projects/%dURN%/owners/trackChangeStatus',
  ACCEPT_PENDING_TRACKCHANGES: '/dashboard-api/trackchanges/%dURN%/accept',
  GET_BUILD_INFO: '/dashboard-api/build/info',
  GET_USER_ROLES: '/dashboard-api/roles/getAllRoles',
  GET_USERS: '/dashboard-api/projects/entity/%entityUrn%/getUsers',
  UPDATE_USER_ROLE: '/dashboard-api/projects/entity/%entityUrn%/updateUserRole',
  REMOVE_USER: '/dashboard-api/projects/entity/%entityUrn%/removeUsers',
  GET_NON_MEMBERS: '/dashboard-api/user/search',
  ADD_USER: '/dashboard-api/projects/entity/%entityUrn%/addUser',
  ADD_BULK_USER: '/dashboard-api/projects/entity/%entityUrn%/addBulkUsers',
  ADD_BULK_USER_TO_PROJECTS: '/dashboard-api/projects/addBulkUsersToProjects',
  GET_PERMISSIONS: '/dashboard-api/permissions',
  POST_CUSTOM_ROLE: '/dashboard-api/roles/create',
  GET_ROLE_PERMISSIONS: '/dashboard-api/roles/%roleId%/permissions',
  POST_UPDATE_ROLE: '/dashboard-api/roles/update',
  TOGGLE_FAVORITE_ROLE: '/dashboard-api/user/toggleFavouriteRole',
  GET_FAVOURITE_ROLES: '/dashboard-api/user/favouriteRoles',
  POST_CREATE_PROJECTS: '/dashboard-api/projects/createNewProjects',
  GET_PEER_REVIEW_ANNOTAIONS:
    '/dashboard-api/spectrum/annotations/entity/%entityUrn%',
  PUT_HIDE_PROJECT: '/dashboard-api/projects/%dURN%/hideProject',
  PUT_UNHIDE_PROJECT: '/dashboard-api/projects/%dURN%/unhideProject',
  GET_NEW_RELEASE_CONTENT_INFO:
    '/dashboard-api/release/whatNewContent/releaseId/%releaseId%',
  ADD_NEW_RELEASE_CONTENT_INFO: '/dashboard-api/release/createNewContent',
  SAVE_GO_LIVE_SEETINGS:
    '/dashboard-api/release/setWhatNewContentDuration/releaseId/',
  GET_USER_PREFERENCE_FOR_NEW_RELEASE_CONTENT:
    '/dashboard-api/release/whatNewUserPreference/releaseId/%releaseId%',
  GET_ALL_SPECIAL_PERMISSIONS:
    '/dashboard-api/user/usersWithSpecialPermissions',
  UPDATE_USER_PREFERENCE_FOR_NEW_RELEASE_CONTENT:
    '/dashboard-api/release/whatNewUserPreference',
  GET_ALFRESCO_IMAGES: '/dashboard-api/alfresco/alfrescoImages',
  DOWNLOAD_ALFRESCO_ASSET: '/dashboard-api/alfresco/alfrescoAsset',
  DOWNLOAD_ALFRESCO_ASSET_PART: '/dashboard-api/alfresco/alfrescoAssetInParts',
  UPDATE_PROJECT_TITLE_AUTHOR:
    '/dashboard-api/projects/%dURN%/updateProjectTitleAndAuthor',
  GET_ALL_RESOURCES: '/dashboard-api/resources/getAllResources',
  ADD_INSTRUCTOR_URL: '/dashboard-api/projects/%dURN%/instructorResource',
  REMOVE_INSTRUCTOR_URL:
    '/dashboard-api/projects/%dURN%/removeInstructorResource',
  GET_DISTRIBUTABLES_PUBLISH_STATUS: '/dashboard-api/publish/status',
  GET_PUBLISHED_LIVE_VERSIONS: '/dashboard-api/publish/publishedLiveVersions',
  GET_NT_DATA: 'dashboard-api/resources/getAllResources',
  REMOVE_NT_DATA: 'dashboard-api/resources/removeResources',
  CREATE_NOTIFICATION: 'dashboard-api/resources/createNewResource',
  UPDATE_NT_DATA: 'dashboard-api/resources/updateResource',
  REMOVE_USER_NOTIFICATION_ALERT: '/dashboard-api/user/removeUserNotification',
  RESET_USER_NOTIFICATION_ALERT: '/dashboard-api/user/resetUserNotification',
  CHECK_NOTIFICATION_ALERT: '/dashboard-api/user/checkNotificationAlert',
  UPDATE_SUBJECT: '/dashboard-api/projects/%dURN%/updateSubject',
  UPDATE_THEME: '/dashboard-api/projects/%dURN%/themes',
  UPDATE_KEYSTAGES: '/dashboard-api/projects/%dURN%/updateKeyStage',
  REORDER_RESOURCES: 'dashboard-api/resources/swapResources',
  GET_IMPORT_DETAILS:
    'dashboard-api/projects/contentImport/%dURN%/importhistory',
  GET_PROJECT_ACTIVITIES:
    'dashboard-api/projects/entity/%entityUrn%/activities',
  GET_STATISTICS: '/dashboard-api/projects/%dURN%/comments',
  GET_XRAY_DETAILS: '/dashboard-api/projects/%dURN%/xraydetails',
  REQUEST_PDF: '/dashboard-api/projects/contentImport/%dURN%/importPdf',
  REQUEST_TCC_IMPORT: '/dashboard-api/projects/contentImport/%dURN%/importtcc',
  REQUEST_CSV_IMPORT: '/dashboard-api/projects/contentImport/%dURN%/importcsv',
  START_UPLOAD: 'dashboard-api/resources/start-upload',
  COMPLETE_UPLOAD: 'dashboard-api/resources/complete-upload',
  PARTS_UPLOAD: 'dashboard-api/resources/upload-parts',

  GET_SLIDES: 'dashboard-api/pagetour/getslides',
  UPDATE_SLIDE_VIDEO_URL: 'dashboard-api/pagetour/%slideId%/updatevideourl',
  EXPORT_REQUEST_STATUS: 'dashboard-api/c4t/%dURN%/exporthistory',
  GET_PROJECT_ACTIVITY: '/dashboard-api/projects/visibilitytransactions',
  GET_CYPRESS_RULES: '/dashboard-api/rules/cypressRules',
  GET_WORK_FLOW_ROLES: '/dashboard-api/workflows/cypressWorkflow',
  SET_CYPRESS_RULE_FOR_LOB:
    '/dashboard-api/rules/cypressRules/%lob%/updateRule',
  SET_WORK_FLOW_ROLE_FOR_LOB: '/dashboard-api/workflows/cypressWorkflow/%lob%',
  UPDATE_CONTENT_ROLE: '/dashboard-api/projects/%dURN%/updateContextRole',
  UPDATE_CYPRESS_PLUS: '/dashboard-api/projects/%dURN%/updateCypressPlus',
  DELETE_NON_PDF_SLATES:
    '/dashboard-api/cypress-plus/%dURN%/deleteNonPdfSlates',
  SEED_PDF_SLATE: '/dashboard-api/cypress-plus/%dURN%/seedpdfslatesmetadata',
  GET_CYPRESS_PLUS_ENDPOINT:
    '/dashboard-api/cypress-plus/%dURN%/entity/%entityURN%/getCypressPlus',
  GET_THEME_LIST: '/dashboard-api/projects/themes',
  GET_ASSOCIATED_THEMES_ON_PROJECT:
    '/dashboard-api/projects/%dURN%/associatedThemes',
  GET_USER_BUSINESS_UNIT_AFFILIATIONS:
    '/dashboard-api/user/businessUnitsAffiliation',
  GET_ALL_BUSINESS_UNITS: '/dashboard-api/businessUnits/getAllBusinessUnits',
  GET_ALL_USERS: '/dashboard-api/user/allUserInfo',
  SET_BU_AFFILIATIONS: 'dashboard-api/businessUnits/setBUAffilliations',
  GET_LOBS: '/dashboard-api/projects/getLOBList',
  UPDATE_LOB: '/dashboard-api/projects/%dURN%/updateLOB',
  GET_LEARNING_FRAMEWORK: '/dashboard-api/learningframework/lob/',
  GET_ASSOCIATED_LEARNING_FRAMEWORK_ON_PROJECT:
    '/dashboard-api/learningframework/%dURN%/associations',
  UPDATE_LEARNING_FRAMEWORK:
    '/dashboard-api/learningframework/addProjectAssociation',
  DELETE_LEARNING_FRAMEWORK:
    '/dashboard-api/learningframework/%dURN%/%LFUrn%/deleteAssociation',
  CHECK_ACCESS_ON_PROJECT: '/dashboard-api/user/%entityUrn%/checkaccess',
  EXPORT_INDESIGN_URL: '/dashboard-api/c4t/inDesignExport',
  FILE_URLS: '/dashboard-api/fileUrls',
  FILE_URLS_LOGS: '/dashboard-api/fileUrls/logs',
  FILE_URLS_UPDATE: '/dashboard-api/fileUrls/%fileKey%/update',
  GET_FEATURES_LIST: '/dashboard-api/featureManager/getFeatures',
  UPDATE_FEATURES_LIST: '/dashboard-api/featureManager/togglefeatures',
  UPDATE_PROJECT_CATEGORY: '/dashboard-api/projects/%dURN%/updateCategory',
  CHECK_PARTNER_HEALTH: '/dashboard-api/health/partnersApi',
  CHECK_C4SM_HEALTH: '/dashboard-api/health/c4sm',
  UPDATE_PROJECT_CANCEL_STATUS:
    '/dashboard-api/projects/%dURN%/updateProjectCancelStatus',
  UPDATE_REVEL_AUDIO_FLAG_OVERRIDE:
    '/dashboard-api/projects/%dURN%/updateRevelAudioFlagOveride',
  FETCH_CONFIG_DATA: '/dashboard-api/configData/%configDataKey%',
  UPDATE_SPECIAL_PERMISSIONS:
    '/dashboard-api/user/updateSpecialPermissionsOfUser',
  CYPRESS_ACTIVE_USERS: '/dashboard-api/user/cypressActiveUsers',
  ADD_USERS_TO_AI_ACCESS: '/dashboard-api/aiContent/access/addUsers',

  // v2 servers calls
  GET_DISCIPLINE_IDS: 'dashboard-api-v2/v2/c4t/printondemand/disciplines',
  CONVERT_TO_PDF: 'dashboard-api-v2/v2/c4t/printondemand',
  GET_THEMES: 'dashboard-api-v2/v2/c4t/printondemand/themes',
  GET_CONFIG: '/dashboard-api-v2/v2/config',
  PREVIEW_PROJECT: 'dashboard-api-v2/v2/c4t/projectpreview',
  PREVIOUS_PREVIEW_DETAILS:
    'dashboard-api-v2/v2/c4t/getAllPreviewTransactions/%dURN%',
  LOGOUT_URL: '/dashboard-api-v2/v2/logout/action',
  ALFRESCO_PATCH_URL: '/dashboard-api/alfresco/%dURN%/updateAlfrescoDetails',
  GET_ITEM_BANKS: 'dashboard-api-v2/v2/learnosity/itembanks/',
  ASSOCIATED_ITEM_BANK:
    'dashboard-api-v2/v2/learnosity/itembank/entity/%entityurn%/associated',
  ADD_ITEM_BANK: 'dashboard-api-v2/v2/learnosity/itembank/add',
  TRACK_CHANGES: '/dashboard-api-v2/v2/cypress/%dURN%/trackchanges',
  START_ENABLE_AUTO_NUMBERING:
    '/dashboard-api/projects/%dUrn%/autoNumbering/startEnableProcess',
  GET_ENABLE_AUTO_NUMBERING_STATUS:
    '/dashboard-api/projects/%dUrn%/autoNumbering/%transactionId%/getEnableProcessStatus',
  UPDATE_AUTO_NUMBERING: '/dashboard-api/projects/%dURN%/updateAutoNumbering',
  PRE_FLIGHT_STATUS_BY_TASK_ID:
    '/dashboard-api-v2/v2/c4sm/%dUrn%/preflightcheck/status/taskId/%taskId%',
  PRE_FLIGHT_RUN_REPORT: 'dashboard-api-v2/v2/c4sm/preflightcheck/report',
  PRE_FLIGHT_GET_ALL_REPORT:
    'dashboard-api-v2/v2/c4sm/preflightcheck/getAllReports',
  POST_EPUB_URL: 'dashboard-api-v2/v2/c4t/epub',
  GET_EPUB_THEMES_URL: 'dashboard-api-v2/v2/c4t/epubthemes',
  VALIDATE_ISBN: '/dashboard-api/isbn/%isbn%/validateISBN',
  MATH_ML_STATUS: '/dashboard-api-v2/v2/c4sm/%dUrn%/mathMl/status',
  MATH_ML_REPORT: '/dashboard-api-v2/v2/c4sm/mathMl/report',
  ALFRESCO_SCRIPT_STATUS:
    '/dashboard-api-v2/v2/c4sm/%dUrn%/alfrescoScripts/status',
  ALFRESCO_SCRIPT_REPORT: '/dashboard-api-v2/v2/c4sm/alfrescoScripts/report',
  EPUB_BODY_CONSTANTS: {
    READER_EPUB: 'Reader+ ePub',
    DELIVERY_LOCATION: 'c4-pod-interim',
    DELIVERY_STYLE: 'S3 Package',
    PROJECT_SCOPE: 'Entire Title',
    RESPONSE_LOCATION: 'SNS ARN',
    RESPONSE_STYLE: 'Wait For Response'
  },
  OCULUS_LAUNCH_URL: '%dURN%/distribute?platform=',
  ETEXT_BAU__PUBLISH_URL: 'publishspa/%productID%/publish',
  WILLOW_LF_URL: '%lob%/learning-frameworks/detail?urn=%urn%',
  // Preferences
  GET_MASTER_DATA_FOR_PREFERENCES:
    '/dashboard-api/preferences/getMasterPreferences',
  GET_USER_PREFERENCE: '/dashboard-api/user/preferencesFilter',
  SAVE_USER_PREFERENCE: '/dashboard-api/user/updatePreferencesFilter',
  UPDATE_PROJECT_NOTES: '/dashboard-api/projects/%dURN%/updateImportantNotes',
  UPDATE_ALFRESCO_EPUB_DATA:
    '/dashboard-api/projects/%dURN%/updateAlfrescoEpubPath',
  GET_ALL_LOB_META: '/dashboard-api/lobMeta',
  GET_META_MASTER_DATA: '/dashboard-api/lobMeta/masterData',
  UPDATE_LOB_META: '/dashboard-api/lobMeta/%lobId%',
  UPDATE_ADDITIONAL_METADATA:
    '/dashboard-api/projects/%dURN%/updateAdditionalMetadata',

  // LOB routes
  GET_TAXONOMIES:
    '/dashboard-api/lineOfBusiness/%lobId%/taxonomyType/%taxonomyType%/taxonomies',
  GET_TAXONOMIES_V2:
    '/dashboard-api/lineOfBusiness/%lobId%/taxonomyType/%taxonomyType%/v2/taxonomies',
  CREATE_NEW_TAXONOMY:
    '/dashboard-api/lineOfBusiness/%lobId%/taxonomyType/%taxonomyType%/context/%context%/taxonomies',
  GET_TAXONOMIES_TYPES: '/dashboard-api/lineOfBusiness/%lobId%/taxonomyType',

  // reset projectMeta
  RESET_PROJECT_META: '/dashboard-api/projects/%dURN%/reset/%projectMeta%',

  // Elm
  GET_ELM_ASSESSMENTS_INTERACTIVES:
    '/dashboard-api/elm/v1/project/%durn%/assessmentsAndInteractives',

  GET_UNAPPROVED_ASSESSMENTS_INTERACTIVES:
    '/dashboard-api/c4sm/v1/project/%durn%/listUnapprovedAssessment',
  // Cypress
  GET_CYPRESS_ASSESSMENTS: '/dashboard-api/c4sm/v1/project/%durn%/assessments',
  GET_UPDATE_ASSESSMENTS_TXN_STATUS:
    '/dashboard-api/vcs/v1/project/%durn%/assessments/%transactionId%/updateStatus',
  GET_UPDATE_ALL_ASSESSMENTS_TXN_STATUS:
    '/dashboard-api/c4sm/v1/project/%durn%/taskid/%taskId%/taskStatus',
  GET_CYPRESS_ASSESSMENTS_TRANSACTIONID:
    '/dashboard-api/c4sm/v1/project/%durn%/getAllAssessmentsTransactionId',
  GET_CYPRESS_ASSESSMENTS_TRANSACTIONID_STATUS:
    '/dashboard-api/c4sm/v1/project/%durn%/allAssessments/transactionId/status/%transactionId%',

  // VCS
  UPDATE_ASSESSEMENT: '/dashboard-api/vcs/v1/project/%durn%/assessments',

  // distribution
  GET_NON_GPS_PRODUCT_MODEL:
    '/dashboard-api/distribution/productModel/%platform%',
  GET_PUBLISH_DETAILS:
    'dashboard-api/distribution/version/%dUrn%/publishDetails/%platform%',
  GET_PUBLISH_TRANSACTIONS:
    'dashboard-api/distribution/version/%dUrn%/transactions',
  PUBLISH_DURN: 'dashboard-api/distribution/version/%dUrn%/publish',
  DISTRIBUTION_WORKFLOWS:
    'dashboard-api/distribution/version/transaction/%transactionId%/workflowSteps',
  DISTRIBUTION_POST_C6_WORKFLOWS:
    'dashboard-api/distribution/version/transaction/%transactionId%/workflowSteps/postC6',
  PRODUCT_MODELS_MAPPING: 'dashboard-api/configData/productModelsMapping',
  SEND_NOTIFICATION_TO_ELM:
    'dashboard-api/elm/project/distributableUrn/%dURN%/sendNotification',
  // Blueprint
  GET_IPM_PROJECT_NUMBER_SEARCH:
    '/dashboard-api-v2/v2/blueprint/ipmProjectNumber/%ipmNumber%',
  PATCH_UDB_DETAILS_IN_BP:
    '/dashboard-api-v2/v2/blueprint/addProductInfoUrn/%productInfo%',
  GET_UDB_ATTACHED_PRODUCT_INFO:
    '/dashboard-api-v2/v2/blueprint/productInfo/%dUrn%',
  REMOVE_PRODUCT_INFO_URN:
    '/dashboard-api-v2/v2/blueprint/removeProductInfoUrn/%productInfo%',
  GET_BLUEPRINT_CLEARANCE_STATUS:
    '/dashboard-api-v2/v2/blueprint/productInfoUrn/%productInfo%/clearancestatuscount',
  // Lesson Builder
  GET_TOC_STRUCTURE:
    '/dashboard-api-v2/v2/lessonbuilder/projectUrn/%dURN%/tocContent',
  PUBLISH_LESSON: '/dashboard-api-v2/v2/lessonbuilder/work/%workurn%/publish',
  PUBLISH_LESSONS_STATUS:
    '/dashboard-api-v2/v2/lessonbuilder/transactions/lessonStatus',
  GET_PUBLISH_LESSON_DETAILS_FROM_DB:
    '/dashboard-api-v2/v2/lessonbuilder/projectUrn/%durn%/publishingStatus'
};
